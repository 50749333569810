import download from "downloadjs";
import { http } from "src/lib/http";

export interface IIceV3Settings {
    DeviceId: number;
    StartDt: Date;
    EndDt: Date;
}

export async function getIceV3File(iceV3Settings: IIceV3Settings, filename: string) {
    const { data } = await http.post("/icev3", iceV3Settings, {
        responseType: "blob",
    });
    download(data, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}
