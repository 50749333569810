import { useQuery } from "react-query";

import { axios } from "src/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "src/lib/react-query";
import { PagedCollection, PagedRequest } from "../../../types";
import { IceV3CalculationValueDto } from "../types";

export type GetIceV3CalculationValuesQuery = PagedRequest & {
    DeviceId: number;
};

export const getIceV3CalculationValues = (
    query: GetIceV3CalculationValuesQuery
): Promise<PagedCollection<IceV3CalculationValueDto>> => {
    return axios.get(`/devices/${query.DeviceId}/ice-v3-calculation-values`, {
        params: {
            ...query,
        },
    });
};

type QueryFnType = typeof getIceV3CalculationValues;

type UseIceV3CalculationValuesOptions = {
    query: GetIceV3CalculationValuesQuery;
    config?: QueryConfig<QueryFnType>;
};

export const useIceV3CalculationValues = ({ query, config }: UseIceV3CalculationValuesOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ["/devices/[query.deviceId]/ice-v3-calculation-values", query.DeviceId, query.Skip, query.Take],
        queryFn: () => getIceV3CalculationValues(query),
    });
};
