import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";

export type ToggleIceV3CalculationEnabledCommand = {
    DeviceId: number;
    IsEnabled: boolean;
};

export const toggleIceV3Calculation = (data: ToggleIceV3CalculationEnabledCommand) => {
    return axios.post(`/devices/${data.DeviceId}/ice-v3-calculation/toggle`, data);
};

type UseToggleIceV3CalculationOptions = {
    config?: MutationConfig<typeof toggleIceV3Calculation>;
};

export const useToggleIceV3Calculation = ({ config }: UseToggleIceV3CalculationOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t("Ошибка переключения расчета гололеда!"),
                color: "red",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries("devices");
            showNotification({
                title: t("Успех!"),
                message: t("Успешно переключен расчет гололеда!"),
                color: "teal",
            });
        },
        ...config,
        mutationFn: toggleIceV3Calculation,
    });
};
