import {
    Badge,
    Button,
    Drawer,
    Group,
    Text,
    LoadingOverlay,
    Pagination,
    Paper,
    Stack,
    Switch,
    Tooltip,
    ActionIcon,
} from "@mantine/core";
import { IconSnowflake, IconUpload } from "@tabler/icons-react";
import dayjs from "dayjs";
import { DataTable } from "mantine-datatable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useIceV2CalculationValues } from "../api/getIceV2CalculationValues";
import { useIceV2ConstantComponent } from "../api/getIceV2ConstantComponent";
import { useIceV2Parameters } from "../api/getIceV2Parameters";
import { useToggleIceV2Calculation } from "../api/toggleIceV2Calculation";
import { useSelectedDevice } from "../hooks/useSelectedDevice";
import { IceV2ConstantComponentDrawer } from "./IceV2ConstantComponentDrawer";
import { IceV2ParametersDrawer } from "./IceV2ParametersDrawer";
import { useDimensionParameters } from "../api/getDimensionParameters";
import { DimensionParametersDrawer } from "./DimensionParametersDrawer";
import { useDisclosure } from "@mantine/hooks";
import { IceV2FileSaverDrawer } from "./IceV2FileSaverDrawer";
import { useToggleIceV3Calculation } from "../api/toggleIceV3Calculation";
import { IceV3FileSaverDrawer } from "./IceV3FileSaverDrawer";
import { useIceV3CalculationValues } from "../api/getIceV3CalculationValues";

type IceV3CalculationProps = {};

export const IceV3Calculation: React.FC<IceV3CalculationProps> = () => {
    const { t } = useTranslation();
    const [isIceV2ParametersDrawerOpen, setIceV2ParametersDrawerOpen] = useState(false);
    const [isIceV2OffsetAngleDrawerOpen, setIceV2OffsetAngleDrawerOpen] = useState(false);

    const [iceFileSaverOpened, iceFileSaverHandlers] = useDisclosure(false);

    const selectedDevice = useSelectedDevice();

    const toggleIceMutation = useToggleIceV3Calculation();

    const { data: iceV2ConstantComponent } = useIceV2ConstantComponent({
        query: {
            deviceId: selectedDevice!.Id,
        },
    });

    const { data: iceV2Parameters } = useIceV2Parameters({
        query: {
            deviceId: selectedDevice!.Id,
        },
    });

    const [activePage, setPage] = useState(1);

    const { data } = useIceV3CalculationValues({
        query: {
            DeviceId: selectedDevice?.Id!,
            Skip: (activePage - 1) * 100,
            Take: 100,
        },
        config: {
            keepPreviousData: true,
        },
    });

    const [calculationEnabled, setCalculationEnabled] = useState(selectedDevice!.IceV3CalculationEnabled);

    return (
        <Paper className="mb-4" p="md" shadow="sm">
            <LoadingOverlay visible={toggleIceMutation.isLoading} />
            <Stack>
                <Group position="apart">
                    <Group>
                        <Text>{t("Расчет включен")}</Text>
                        <Switch
                            sx={{ display: "flex" }}
                            size="xl"
                            onLabel="ON"
                            offLabel="OFF"
                            checked={calculationEnabled}
                            onChange={(event) => {
                                setCalculationEnabled(event.currentTarget.checked);
                                toggleIceMutation.mutateAsync({
                                    DeviceId: selectedDevice!.Id,
                                    IsEnabled: event.currentTarget.checked,
                                });
                            }}
                        ></Switch>
                    </Group>
                    <Group>
                        <Tooltip label={t("Скачать").toString()} withArrow>
                            <ActionIcon onClick={iceFileSaverHandlers.open} size="lg" variant="default">
                                <IconUpload size={20} />
                            </ActionIcon>
                        </Tooltip>
                        <Button
                            leftIcon={<IconSnowflake />}
                            onClick={() => {
                                setIceV2ParametersDrawerOpen(true);
                            }}
                            variant="outline"
                        >
                            {t("Параметры расчета")}
                        </Button>
                        <Button
                            leftIcon={<IconSnowflake />}
                            onClick={() => {
                                setIceV2OffsetAngleDrawerOpen(true);
                            }}
                            variant="outline"
                        >
                            {t("Постоянная составляющая")}
                        </Button>
                    </Group>
                </Group>
                <DataTable
                    minHeight={150}
                    withBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={data?.Page}
                    // define columns
                    columns={[
                        {
                            accessor: "Timestamp",
                            title: "Timestamp",
                            width: 100,
                            render: ({ Timestamp }) => dayjs(Timestamp).format("DD.MM.YY HH:mm"),
                        },
                        { accessor: "Temperature" },
                        {
                            accessor: "AccelerometerTemperature",
                            title: "AccelerometerTemperature",
                            render: ({ AccelerometerTemperature }) =>
                                AccelerometerTemperature
                                    ? Number(AccelerometerTemperature).toFixed(8)
                                    : AccelerometerTemperature,
                        },
                        { accessor: "Angle", title: "Angle" },
                        {
                            accessor: "a1",
                            title: "a1",
                            render: ({ ChainLineParameterState }) =>
                                ChainLineParameterState
                                    ? Number(ChainLineParameterState).toFixed(8)
                                    : ChainLineParameterState,
                        },
                        {
                            accessor: "lб1",
                            title: "lб1",
                            render: ({ GivenTemperatureLineLength }) =>
                                GivenTemperatureLineLength
                                    ? Number(GivenTemperatureLineLength).toFixed(8)
                                    : GivenTemperatureLineLength,
                        },
                        {
                            accessor: "a°M",
                            title: "a°M",
                            render: ({ TangentAnglePredictedByWireModel }) =>
                                TangentAnglePredictedByWireModel
                                    ? Number(TangentAnglePredictedByWireModel).toFixed(8)
                                    : TangentAnglePredictedByWireModel,
                        },
                        {
                            accessor: "a°M2-A",
                            title: "a°M2-A",
                            render: ({ TangentAngle }) =>
                                TangentAngle ? Number(TangentAngle).toFixed(8) : TangentAngle,
                        },
                        {
                            accessor: "aC",
                            title: "aC",
                            render: ({ AngleMeasuredByAccelerometer }) =>
                                AngleMeasuredByAccelerometer
                                    ? Number(AngleMeasuredByAccelerometer).toFixed(8)
                                    : AngleMeasuredByAccelerometer,
                        },
                    ]}
                    // execute this callback when a row is clicked
                    // onRowClick={({ name, party, bornIn }) =>
                    //     alert(`You clicked on ${name}, a ${party.toLowerCase()} president born in ${bornIn}`)
                    // }
                />
                {data && data.Total ? (
                    <Group my="md" position="right">
                        <Pagination
                            page={activePage}
                            onChange={setPage}
                            total={Math.floor(data.Total / 100)}
                            withEdges
                        />
                    </Group>
                ) : null}
            </Stack>

            <Drawer
                opened={isIceV2ParametersDrawerOpen}
                onClose={() => setIceV2ParametersDrawerOpen(false)}
                title={t("Настройки гололеда")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                {iceV2Parameters && (
                    <IceV2ParametersDrawer
                        iceV2Parameters={iceV2Parameters}
                        deviceId={selectedDevice!.Id}
                        setOpen={setIceV2ParametersDrawerOpen}
                    />
                )}
            </Drawer>
            <Drawer
                opened={isIceV2OffsetAngleDrawerOpen}
                onClose={() => setIceV2OffsetAngleDrawerOpen(false)}
                title={t("Постоянная составляющая")}
                padding="xl"
                size="xl"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                {iceV2ConstantComponent && (
                    <IceV2ConstantComponentDrawer
                        iceV2ConstantComponent={iceV2ConstantComponent}
                        deviceId={selectedDevice!.Id}
                        setOpen={setIceV2OffsetAngleDrawerOpen}
                    />
                )}
            </Drawer>
            <Drawer
                opened={iceFileSaverOpened}
                onClose={iceFileSaverHandlers.close}
                title={t("Скачать данные расчёта гололёда").toString()}
                padding="md"
                size="xl"
                position="right"
                styles={{
                    drawer: {
                        overflowY: "auto",
                    },
                }}
            >
                <IceV3FileSaverDrawer device={selectedDevice} onComplete={iceFileSaverHandlers.close} />
            </Drawer>
        </Paper>
    );
};

IceV3Calculation.displayName = "IceV2Calculation";
