import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SchemaOf, object, string, number, boolean, date } from "yup";
import { Box, Button, Group, NumberInput, Select, Switch, Textarea, TextInput } from "@mantine/core";
import { UpdateDeviceCommand, useUpdateDevice } from "src/features/devices/api/updateDevice";
import { DeviceDto } from "src/features/devices/types";
import { debounce } from "lodash";
import SelectPositionMap from "src/components/Monitoring/SelectPositionMap";
import { useForm, yupResolver } from "@mantine/form";
import { DatePicker } from "@mantine/dates";

const UpdateDeviceCommandSchema: SchemaOf<UpdateDeviceCommand> = object({
    Id: number().required(),
    Name: string().required("Имя не может быть пустым"),
    Comment: string().required().min(0),
    Latitude: number().nullable(),
    Longitude: number().nullable(),
    DeviceModelId: number().required(),
    AutoTimeZoneDetection: boolean().required(),
    AutoTimeZoneSeasonChanging: boolean().required(),
    SummerTimeZoneChanging: date().nullable(),
    WinterTimeZoneChanging: date().nullable(),
    TimeZone: number().nullable(),
});

interface CreateDeviceDrawerProps {
    device: DeviceDto;
    onComplete: () => void;
}

export const UpdateDeviceDrawer: React.FC<CreateDeviceDrawerProps> = ({ device, onComplete }) => {
    const { t } = useTranslation();
    const updateDeviceMutation = useUpdateDevice();

    const [coordinatesLocal, setCoordinatesLocal] = useState<[number | null | undefined, number | null | undefined]>([
        device.Latitude,
        device.Longitude,
    ]);

    const form = useForm<UpdateDeviceCommand>({
        initialValues: {
            Id: device.Id,
            Name: device.Name,
            Comment: device.Comment,
            Latitude: device.Latitude,
            Longitude: device.Longitude,
            DeviceModelId: device.DeviceModel,
            AutoTimeZoneDetection: device.AutoTimeZoneDetection,
            AutoTimeZoneSeasonChanging: device.AutoTimeZoneSeasonChanging,
            SummerTimeZoneChanging: device.SummerTimeZoneChanging,
            WinterTimeZoneChanging: device.WinterTimeZoneChanging,
            TimeZone: device.TimeZone,
        },
        validate: yupResolver(UpdateDeviceCommandSchema),
    });

    function handleClose() {
        onComplete();
        form.reset();
    }

    return (
        <form
            className="flex flex-col overflow-y-scroll"
            onSubmit={form.onSubmit(async (values) => {
                await updateDeviceMutation.mutateAsync({
                    Id: values.Id,
                    Name: values.Name,
                    Comment: values.Comment,
                    Latitude: values.Latitude,
                    Longitude: values.Longitude,
                    DeviceModelId: values.DeviceModelId,
                    AutoTimeZoneDetection: values.AutoTimeZoneDetection,
                    AutoTimeZoneSeasonChanging: values.AutoTimeZoneSeasonChanging,
                    SummerTimeZoneChanging: values.SummerTimeZoneChanging,
                    WinterTimeZoneChanging: values.WinterTimeZoneChanging,
                    TimeZone: values.TimeZone,
                });
                handleClose();
            })}
        >
            <div className="space-y-4 flex-auto">
                <TextInput placeholder={t("Название")} label={t("Название")} {...form.getInputProps("Name")} />
                <Textarea placeholder={t("Комментарий")} label={t("Комментарий")} {...form.getInputProps("Comment")} />
                <Select
                    value={form.values.DeviceModelId.toString()}
                    onChange={(value) => {
                        form.setFieldValue("DeviceModelId", Number(value));
                    }}
                    label={t("Тип устройства")}
                    data={[
                        { value: "1", label: t("МДД") },
                        { value: "2", label: t("КЗ") },
                        { value: "3", label: t("Универсальное") },
                        { value: "4", label: t("Универсальное 2.1") },
                        { value: "5", label: t("Универсальное 2.2") },
                    ]}
                />
                <Box
                    sx={{
                        height: "300px",
                    }}
                >
                    <SelectPositionMap
                        device={{
                            Id: 0,
                            CCID: device.CCID,
                            Comment: form.values.Comment,
                            Name: form.values.Name,
                            DeviceModel: device.DeviceModel,
                            Latitude: coordinatesLocal[0],
                            Longitude: coordinatesLocal[1],
                            DeviceParameters: device.DeviceParameters,
                            DeviceChannels: device.DeviceChannels,
                            IceV2CalculationEnabled: device.IceV2CalculationEnabled,
                            IceV3CalculationEnabled: device.IceV3CalculationEnabled,
                            AutoTimeZoneDetection: form.values.AutoTimeZoneDetection,
                            AutoTimeZoneSeasonChanging: form.values.AutoTimeZoneSeasonChanging,
                            SummerTimeZoneChanging: form.values.SummerTimeZoneChanging,
                            WinterTimeZoneChanging: form.values.WinterTimeZoneChanging,
                            TimeZone: form.values.TimeZone ?? null,
                            GetOscilloscope: device.GetOscilloscope,
                            TimeSynchronization: device.TimeSynchronization,
                        }}
                        onChange={debounce((value) => {
                            form.setFieldValue("Latitude", value[0]);
                            form.setFieldValue("Longitude", value[1]);
                        }, 300)}
                        onClick={(value) => {
                            setCoordinatesLocal(value);
                        }}
                    />
                </Box>
                <Group>
                    <NumberInput
                        placeholder={t("Широта")}
                        label={t("Широта")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Latitude")}
                    />
                    <NumberInput
                        placeholder={t("Долгота")}
                        label={t("Долгота")}
                        precision={4}
                        hideControls
                        {...form.getInputProps("Longitude")}
                    />
                </Group>
                <Switch
                    label={t("Автоматическое определение часового пояса")}
                    {...form.getInputProps("AutoTimeZoneDetection", { type: "checkbox" })}
                />
                {!form.values.AutoTimeZoneDetection && (
                    <>
                        <NumberInput
                            label={t("Часовой пояс")}
                            step={1}
                            min={-11}
                            max={12}
                            {...form.getInputProps("TimeZone")}
                        />
                    </>
                )}
                <Switch
                    label={t("Изменение часового времени на летнее")}
                    {...form.getInputProps("AutoTimeZoneSeasonChanging", { type: "checkbox" })}
                />
                {form.values.AutoTimeZoneSeasonChanging && (
                    <>
                        <DatePicker
                            label={t("Дата перехода на летнее время")}
                            {...form.getInputProps("SummerTimeZoneChanging")}
                        ></DatePicker>
                        <DatePicker
                            label={t("Дата перехода на зимнее время")}
                            {...form.getInputProps("WinterTimeZoneChanging")}
                        ></DatePicker>
                    </>
                )}
            </div>
            <div className="space-x-3 mt-8 flex justify-end">
                <Button color="primary" variant="outline" onClick={handleClose}>
                    {t("Отмена")}
                </Button>
                <Button color="primary" type="submit">
                    {t("Сохранить")}
                </Button>
            </div>
        </form>
    );
};
